import { Actions } from 'Store/auth/actions';
import { AnyAction } from '@reduxjs/toolkit';
import {
  CompanyType, Currency,
  EnvironmentMode,
  Gender,
  SignupSteps,
  UserStatus,
  UserVerification,
} from 'Constants/enums';
import { Endorsement } from 'Types/endorsement.interface';
import { UserPrivateInfo } from 'Types/userPrivateInfo.interface';
import { AccountInfo } from 'Types/accountInfo.interface';
import { LoggedUserData } from 'Types/loggedUserData.interface';
import { OptimizedUserData } from "Types/optimizedUserData.interface";

interface State {
  isInitialized: boolean;
  userEndorsements: Endorsement[];
  signupStep: {
    step: SignupSteps | null;
  };
  user: {
    data: LoggedUserData;
    isLoading: boolean;
  };
  userProfile: {
    data: OptimizedUserData;
  },
  userPrivateInfo: {
    data: UserPrivateInfo;
  };
  tags: Partial<Record<UserVerification, { displayName: string; id: UserVerification; }>>;
  accountInfo: {
    data?: AccountInfo;
  };
}

const initialState: State = {
  isInitialized: false,
  user: {
    data: {
      uid: '',
      email: '',
      emailVerified: false,
      displayName: '',
      isAnonymous: false,
      photoURL: '',
    },
    isLoading: false,
  },
  userProfile: {
    data: {} as OptimizedUserData,
  },
  userPrivateInfo: {
    data: {
      email: '',
      phoneNumber: '',
      dob: '',
      gender: Gender.Other,
      citizenship: '',
      psgChatId: '',
      userStatus: UserStatus.Unknown,
      environmentMode: EnvironmentMode.Default,
      companyInfo: {
        companyId: '',
        pin: '',
        name: '',
        countryCode: '',
        currency: Currency.USD,
        timezone: '',
        createdAt: 0,
        orgType: CompanyType.Unknown,
      },
      inviteCode: '',
      roles: [],
    },
  },
  tags: {},
  userEndorsements: [],
  accountInfo: {},
  signupStep: {
    step: null,
  },
};

const reducer = (state: State = initialState, action: AnyAction): State => {
  const { payload } = action;

  switch (action.type) {
    case Actions.Loading:
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: payload,
        },
      };

    case Actions.SetIsInitialized:
      return {
        ...state,
        isInitialized: payload,
      };

    case Actions.GetLoggedUserInfo:
      return {
        ...state,
        user: {
          ...state.user,
          data: {
            ...state.user.data,
            ...payload,
          },
        },
      };

    case Actions.GetProfile:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          data: payload,
        },
      };

    case Actions.GetTags:
      return {
        ...state,
        tags: payload,
      };

    case Actions.GetEndorsements:
      return {
        ...state,
        userEndorsements: payload,
      };

    case Actions.GetPrivateInfo:
      return {
        ...state,
        userPrivateInfo: {
          data: payload,
        },
      };

    case Actions.UpdateRegistrationStep:
      return {
        ...state,
        signupStep: {
          step: payload,
        },
      };

    case Actions.GetAccountInfo:
      return {
        ...state,
        accountInfo: {
          data: payload,
        },
      };

    case Actions.Reset:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default reducer;
