import { NavigateFunction } from 'react-router-dom';
import {
  AuthErrorCodes,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAdditionalUserInfo,
  OAuthProvider,
  onAuthStateChanged,
  RecaptchaVerifier,
  signInWithPopup,
  signOut,
  updateProfile,
  User,
} from 'firebase/auth';
import { FormattedMessage } from 'react-intl';
import { AnyAction } from '@reduxjs/toolkit';
import { notificationToast } from 'Utils/notificationToaster';

import { UserService } from 'Services/UserService';
import { updateDataAsyncAction } from 'Store/genericActions';
import { postEvent } from 'Services/Api';
import {
  firebaseGetClientsRef,
  firebaseGetCurrentUser,
  firebaseGetCurrentUserProviderId,
  firebaseGetUserCommentsRef,
  firebaseGetUserDataRef,
} from 'Services/FirebaseService';
import ClientsService from 'Services/ClientService';

import { GenericReducerName } from 'Constants/genericReducerName';
import { GenericReducerProp } from 'Constants/genericReducerProp';
import { AuthProvider, ClientInfoStatus, QueueAlias, SignupSteps, UserResultStatus, UserType } from 'Constants/enums';
import { convertObjToArray } from 'Utils/helpers';
import { HOME_ROUTE } from 'Constants/routes';
import { AppDispatch } from 'Types/redux.types';
import { auth, getIntlMessageFromFirebaseError } from 'Utils/firebase';
import { setUserInfo } from 'Store/settings/actions';
import { canHaveAccountLocked } from 'Store/permissions/selectors';
import { getUserAgent } from 'Utils/api';
import { extractNameFromEmail, formatUserData } from 'Utils/user';

export enum Actions {
  Loading = 'authUser/Loading',
  GetEndorsements = 'authUser/GetEndorsements',
  GetProfile = 'authUser/GetProfile',
  GetPrivateInfo = 'authUser/GetPrivateInformation',
  GetLoggedUserInfo = 'authUser/LoggedUser',
  UpdateRegistrationStep = 'authUser/UpdateRegistrationStep',
  GetAccountInfo = 'authUser/GetAccountInfo',
  GetTags = 'authUser/GetTags',
  SetIsInitialized = 'authUser/SetIsInitialized',
  Reset = 'authUser/ResetLoggedUser',
}

declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier;
  }
}

export const setIsInitialized = (val: boolean) => (dispatch: AppDispatch) => {
  dispatch({ type: Actions.SetIsInitialized, payload: val });
};

export const extractUserInfo = (user: User | null) => {
  const { uid, email, emailVerified, displayName, isAnonymous, photoURL } = user || {};

  return {
    uid,
    email,
    emailVerified,
    displayName,
    isAnonymous,
    photoURL,
  };
};

export const subscribeToAuthUserEndorsements = (userId: string) => (dispatch: AppDispatch) => {
  if (!userId) return;

  try {
    firebaseGetUserCommentsRef()
      .child('users')
      .child(userId)
      .on('value', (dataSnapshot) => {
        const data = convertObjToArray(dataSnapshot.val());
        dispatch({ type: Actions.GetEndorsements, payload: data });
      });
  } catch (e) {
    console.error(e);
  }
};

export const unsubscribeFromAuthUserEndorsements = () => firebaseGetUserCommentsRef().off();

export const getAuthUserPrivateInfo = (userId: string) => (dispatch: AppDispatch) =>
  UserService.getPrivateInfo(userId)
    .then((response) => {
      dispatch({ type: Actions.GetPrivateInfo, payload: response });
    });

export const getUserProfile = (userId: string) => (dispatch: AppDispatch) =>
  UserService.getUser(userId)
    .then((response) => {
      if (response?.tags && response.tags?.tags) {
        dispatch({ type: Actions.GetTags, payload: response.tags.tags });
      }

      dispatch({
        type: Actions.GetProfile,
        payload: formatUserData(response),
      });
    });

// TODO: specify data type
export const saveBasicInfo = (data: any) => {
  const currentUser = firebaseGetCurrentUser();
  const uid = currentUser ? (currentUser as User)?.uid : null;

  if (!uid) return;

  return firebaseGetUserDataRef(uid)
    .update(data)
    .then(() => UserService.updateUser(data))
    .catch((error) => Promise.reject(error));
};

export const verifyUserPin = (userPin: string) => (dispatch: AppDispatch): Promise<{ id: string }> => {
  dispatch({ type: Actions.Loading, payload: true });

  return new Promise((resolve) => firebaseGetClientsRef(userPin)
    .once('value', (datasnapshot) => {
      const data = datasnapshot.val();

      if (data) {
        return resolve(data);
      }

      notificationToast.error(<FormattedMessage id="invalid_pin" />);
    })
    .catch(() => notificationToast.error(<FormattedMessage id="invalid_pin" />))
    .finally(() => dispatch({ type: Actions.Loading, payload: false })));
};

export const fetchCurrentUser = () => (dispatch: AppDispatch) => {
  dispatch({ type: Actions.Loading, payload: true });

  onAuthStateChanged(auth, (user) => {
    if (user) {
      const { uid, email, emailVerified, displayName, isAnonymous, photoURL } = user;

      const data = {
        uid,
        email,
        emailVerified,
        displayName,
        isAnonymous,
        photoURL,
      };

      dispatch({ type: Actions.GetLoggedUserInfo, payload: data });
      dispatch({ type: Actions.Loading, payload: false });
    } else {
      dispatch({ type: Actions.Loading, payload: false });
    }
  });
};

export const logoutUserFromFirebase = (data?: { showNotification?: boolean; clearPin?: boolean; }) => (dispatch: AppDispatch) => {
  const { showNotification, clearPin } = data || {};

  dispatch({ type: Actions.Loading, payload: true });

  return signOut(auth)
    .then(() => {
      dispatch({ type: Actions.Loading, payload: false });
      dispatch({ type: Actions.Reset });
      dispatch(updateDataAsyncAction(GenericReducerName.Auth, GenericReducerProp.ResetState, null));

      if (clearPin) {
        localStorage.removeItem('pin');
      }

      if (showNotification) {
        notificationToast.success(<FormattedMessage id="notification.success.logout" />);
      }

      Promise.resolve();
    })
    .catch((error) => {
      dispatch({ type: Actions.Loading, payload: false });
      notificationToast.error(error.message);
      Promise.reject(error);
    });
};

export const signupUserInFirebase = (
  { email, password, name, clientId }: { email: string; password: string; name: string; clientId?: string; },
  onSuccess?: () => void,
) => (dispatch: AppDispatch) => {
  dispatch({ type: Actions.Loading, payload: true });

  const verifier: RecaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', {
    callback: () => {
      createUserWithEmailAndPassword(auth, email, password)
        .then(async () => {
          const user: User = firebaseGetCurrentUser() as User;

          await updateProfile(user, { displayName: name });
          const response = await UserService.updateUser({
            ...(clientId ? { clientId } : {}),
            userId: user.uid,
            email,
            name,
          });

          dispatch({ type: Actions.UpdateRegistrationStep, payload: SignupSteps.NiceToMeet });
          dispatch({ type: Actions.GetLoggedUserInfo, payload: extractUserInfo(user) });

          if (clientId) {
            localStorage.setItem('pin', clientId);
          }

          return response;
        })
        .then(() => {
          dispatch({ type: Actions.Loading, payload: false });

          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error.code);
          const firebaseErrorMessage = getIntlMessageFromFirebaseError(error?.code);

          verifier.clear();
          dispatch({ type: Actions.Loading, payload: false });
          notificationToast.error(firebaseErrorMessage || error.message);
        });
    },
  });

  verifier.render();

  window.recaptchaVerifier = verifier; // TODO: remove?
};

export const signinUserWithProvider = (providerName: string, pin: string, matchEmail: string, onSuccess: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: Actions.Loading, payload: true });
    const provider = new OAuthProvider(providerName);
    provider.setCustomParameters({
      prompt: 'select_account'
    });

    const authResult = await signInWithPopup(auth, provider);
    const { user } = authResult;

    if (matchEmail && user?.email !== matchEmail) {
      dispatch(logoutUserFromFirebase());
      notificationToast.error('notification.error.usedWrongEmailGoogle');
    } else {
      dispatch({ type: Actions.GetLoggedUserInfo, payload: extractUserInfo(user as User) });
      const additionalUserInfo = getAdditionalUserInfo(authResult);

      if (additionalUserInfo?.isNewUser) {
        const { email, uid, displayName } = user;
        const userName = displayName || (email ? extractNameFromEmail(email) : undefined);

        await updateProfile(user, { displayName: userName });
        await UserService.updateUserRequest(user.uid, {
          meta: {
            clientId: pin,
            action: 'update_user',
            userId: uid,
            agent: getUserAgent(),
            restEvent: true,
          },
          clientId: pin,
          userId: uid,
          email,
          name: userName,
        });
      }

      if (onSuccess) {
        await onSuccess();
      }
    }
  } catch (error: any) {
    if (![AuthErrorCodes.EXPIRED_POPUP_REQUEST, AuthErrorCodes.POPUP_CLOSED_BY_USER].includes(error?.code)) {
      notificationToast.error(error.message);
    } else {
      notificationToast.error(error.message || 'notification.error.unknown.error');
    }
  } finally {
    dispatch({ type: Actions.Loading, payload: false });
  }
};

export const getSignInMethodsForEmail = (email: string) => fetchSignInMethodsForEmail(auth, email);

export const handleLogin = (navigate: NavigateFunction, pin?: string, redirectTo?: string, callback?: any) => async (dispatch: AppDispatch) => {
  try {
    const userInfo = await UserService.getUserInfo();
    const userResult = await UserService.updateUserRequest(userInfo.userId, {
      meta: {
        action: 'update_user',
        clientId: userInfo.clientId,
        userId: userInfo.userId,
        agent: getUserAgent(),
        restEvent: true,
      },
    });

    if (userResult.userStatus === UserResultStatus.Pending) {
      notificationToast.warning(<FormattedMessage id="label.yourAccountIsPendingApproval" />);
      dispatch(logoutUserFromFirebase() as unknown as AnyAction);

      if (callback) {
        callback();
      }
      return;
    }

    const { block, clientId, userType } = userInfo;
    dispatch(setUserInfo(userInfo) as unknown as AnyAction);

    if (canHaveAccountLocked) {
      const { clientInfo } = await ClientsService.getClientDetails(clientId);
      if (clientInfo?.status === ClientInfoStatus.Disabled) {
        notificationToast.error(<FormattedMessage id="error.accountLockedFromConsole" />);
        dispatch(logoutUserFromFirebase() as unknown as AnyAction);

        if (callback) {
          callback();
        }
        return;
      }
    }

    if (userType === UserType.Individual) {
      notificationToast.error(<FormattedMessage id="error.emailNotFound" />);
      dispatch(logoutUserFromFirebase() as unknown as AnyAction);

      if (callback) {
        callback();
      }
      return;
    }

    if (block) {
      notificationToast.error(<FormattedMessage id="notification.error.account.blocked" />);
      dispatch(logoutUserFromFirebase() as unknown as AnyAction);

      if (callback) {
        callback();
      }
      return;
    }
    if (pin && pin !== clientId) {
      dispatch(logoutUserFromFirebase() as unknown as AnyAction);
      notificationToast.error(<FormattedMessage id="notification.error.pinNotValid" />);
      if (callback) {
        callback();
      }
    } else {
      localStorage.setItem('pin', clientId);
      await updateLoginMethod();
      navigate(redirectTo || HOME_ROUTE);
      notificationToast.success(<FormattedMessage id="notification.success.login" />);
    }
  } catch {
    dispatch(logoutUserFromFirebase() as unknown as AnyAction);
    notificationToast.error(<FormattedMessage id="notification.error.signInNotAllowed" />);
    if (callback) {
      callback();
    }
  }
};

export const handleRestriction = () => async (dispatch: AppDispatch) => {
  try {
    const { block } = await UserService.getUserInfo();

    if (block) {
      notificationToast.error(<FormattedMessage id="notification.error.account.blocked" />);
      dispatch(logoutUserFromFirebase({ clearPin: true }) as unknown as AnyAction);
    }
  } catch (error: any) { // TODO: fix after specifying error type
    dispatch(logoutUserFromFirebase({ clearPin: true }) as unknown as AnyAction);
    notificationToast.error(error?.message);
  }
};

const formatProviderResponse = (response: string) => {
  switch (response) {
    case 'google.com':
      return AuthProvider.Google;
    case 'password':
    default:
      return AuthProvider.Email;
  }
};

export const updateLoginMethod = () => {
  const userLoginProviderId = firebaseGetCurrentUserProviderId();
  const data = {
    signUpMethod: formatProviderResponse(userLoginProviderId),
  };

  return saveBasicInfo(data);
};

export const acceptAgreement = (userId: string, agreedAt?: number) => {
  const legal = { agreedAt: agreedAt || new Date().getTime() };

  firebaseGetUserDataRef(userId).child('legal').set(legal);
  return postEvent(QueueAlias.Legal, { legal });
};
