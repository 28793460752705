import { LocationsService } from 'Services/LocationsService';
import ShopService from 'Services/ShopService';
import ClientsService from 'Services/ClientService';

import { getProductChemicals, getProductCustomChemicals } from "Services/ProductService";

import { AppDispatch, ThunkDispatchType } from 'Types/redux.types';
import { notificationToast } from 'Utils/notificationToaster';
import { Client } from 'Types/client.interface';
import { ShopSettings } from 'Types/shopSettings.interface';
import { CustomChemical, ProductChemicals } from "Types/chemicals.interface";
import { PosType } from "Constants/enums";

export const ACTIONS = {
  SET_US_STATES: 'clientReducer/setUsStates',
  SET_LOCATIONS: 'clientReducer/setLocations',
  SET_CLIENT_ID: 'clientReducer/setClientId',
  SET_ACTIVE_SETTINGS_PAGE: 'clientReducer/setActiveSettingsPage',
  SET_IS_MERCHANT_SETTINGS_SELECTED: 'clientReducer/setIsMerchantSettingsSelected',
  SET_PARENT_SETTINGS: 'clientReducer/setParentSettings',
  SET_CLIENT: 'clientReducer/setClient',
  SET_SHOP_SETTINGS: 'clientReducer/setShopSettings',
  SET_IS_LOADING: 'clientReducer/isLoading',
  SET_IS_SUBMITTING: 'clientReducer/isSubmitting',
  SET_CUSTOM_PRODUCT_CHEMICALS: 'clientReducer/setCustomProductChemicals',
  SET_PRODUCT_CHEMICALS: 'clientReducer/setProductChemicals',
  RESET_LOCATIONS: 'clientReducer/resetLocations',
  RESET_US_STATES: 'clientReducer/resetUsStates',
  RESET_REDUCER: 'clientReducer/resetReducer',
  HARD_RESET_REDUCER: 'clientReducer/hardResetReducer',
};

export const fetchParentSettings = (parentId: string) => async (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.SET_IS_LOADING, payload: true });

  try {
    const response = await ShopService.getShopSettings(parentId);
    dispatch({ type: ACTIONS.SET_PARENT_SETTINGS, payload: response });
  } catch (e: any) {
    notificationToast.error(e.message);
  } finally {
    dispatch({ type: ACTIONS.SET_IS_LOADING, payload: false });
  }
};

export const fetchClient = (companyId: string) => async (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.SET_IS_LOADING, payload: true });

  try {
    const response = await ClientsService.getClientDetails(companyId);
    dispatch({ type: ACTIONS.SET_CLIENT, payload: response });
  } catch (e: any) {
    notificationToast.error(e.message);
  } finally {
    dispatch({ type: ACTIONS.SET_IS_LOADING, payload: false });
  }
};

export const fetchShopSettings = (companyId: string) => async (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.SET_IS_LOADING, payload: true });

  try {
    const response = await ShopService.getClientShopSettings(companyId);
    dispatch({ type: ACTIONS.SET_SHOP_SETTINGS, payload: response });
  } catch (e: any) {
    notificationToast.error(e.message);
  } finally {
    dispatch({ type: ACTIONS.SET_IS_LOADING, payload: false });
  }
};

export const fetchLocations = ({ companyId }: { companyId: string }) => async (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.SET_IS_LOADING, payload: true });

  const response = await LocationsService.getLocations(companyId);

  dispatch({ type: ACTIONS.SET_LOCATIONS, payload: response.list });
  dispatch({ type: ACTIONS.SET_IS_LOADING, payload: false });
};

export const fetchUsStates = () => async (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.SET_IS_LOADING, payload: true });
  const { list: usStates } = await LocationsService.getUSstates();

  const formattedUsStates: Record<string, string> = {};
  usStates.forEach(({ key, value }) => {
    formattedUsStates[key] = value;
  });

  dispatch({ type: ACTIONS.SET_US_STATES, payload: formattedUsStates });
  dispatch({ type: ACTIONS.SET_IS_LOADING, payload: false });
};

export const resetLocations = () => (dispatch: AppDispatch) =>
  dispatch({ type: ACTIONS.RESET_LOCATIONS });

export const resetUsStates = () => (dispatch: AppDispatch) =>
  dispatch({ type: ACTIONS.RESET_US_STATES });

export const fetchLocationsAndStates = (companyId: string) => (dispatch: ThunkDispatchType) => {
  try {
    dispatch(fetchLocations({ companyId }));
    dispatch(fetchUsStates());
  } catch (error: any) {
    notificationToast.error(error.message || 'notification.error.unknown.error');
  }
};

export const resetLocationsAndStates = () => (dispatch: ThunkDispatchType) => {
  dispatch(resetLocations());
  dispatch(resetUsStates());
};

export const resetClientReducer = () => (dispatch: ThunkDispatchType) => dispatch({ type: ACTIONS.RESET_REDUCER });

export const hardResetClientReducer = () => (dispatch: ThunkDispatchType) => dispatch({ type: ACTIONS.HARD_RESET_REDUCER });

export const setIsMerchantSettingsSelected = (isMerchantSettingsSelected: boolean) => (dispatch: ThunkDispatchType) => {
  dispatch({ type: ACTIONS.SET_IS_MERCHANT_SETTINGS_SELECTED, payload: isMerchantSettingsSelected });
};

export const updateClient = (
  client: Partial<Client>,
  clientId: string,
  onSuccess?: () => void,
) => async (dispatch: ThunkDispatchType) => {
  dispatch({ type: ACTIONS.SET_IS_SUBMITTING, payload: true });

  try {
    await ClientsService.updateClient(client, clientId);
    dispatch(fetchClient(clientId));

    if (onSuccess) {
      onSuccess();
    }
  } catch (e: any) {
    notificationToast.error(e.message);
  } finally {
    dispatch({ type: ACTIONS.SET_IS_SUBMITTING, payload: false });
  }
};

export const fetchCustomProductChemicals = (companyId: string, categoryPath: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await getProductCustomChemicals(companyId, { categoryPath });
    dispatch({ type: ACTIONS.SET_CUSTOM_PRODUCT_CHEMICALS, payload: response });
  } catch (e: any) {
    notificationToast.error(e.message);
  }
};

export const fetchProductChemicals = (companyId: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await getProductChemicals(companyId);
    dispatch({ type: ACTIONS.SET_PRODUCT_CHEMICALS, payload: response });
  } catch (e: any) {
    notificationToast.error(e.message);
  }
};

export const updateShopSettings = (
  shopSettings: Partial<ShopSettings>,
  clientId: string,
  posType: PosType,
  onSuccess?: () => void,
  onFail?: () => void,
) => async (dispatch: ThunkDispatchType) => {
  dispatch({ type: ACTIONS.SET_IS_SUBMITTING, payload: true });

  try {
    await ShopService.updateShopSettings(shopSettings, clientId, posType || PosType.Alleaves);
    dispatch(fetchShopSettings(clientId));

    if (onSuccess) {
      onSuccess();
    }
  } catch (e: any) {
    notificationToast.error(e.message);

    if (onFail) {
      onFail();
    }
  } finally {
    dispatch({ type: ACTIONS.SET_IS_SUBMITTING, payload: false });
  }
};

export const setIsLoading = (isLoading: boolean) => (dispatch: ThunkDispatchType) => {
  dispatch({ type: ACTIONS.SET_IS_LOADING, payload: isLoading });
};

export const setIsSubmitting = (isSubmitting: boolean) => (dispatch: ThunkDispatchType) => {
  dispatch({ type: ACTIONS.SET_IS_SUBMITTING, payload: isSubmitting });
};

export const resetParentSettings = () => (dispatch: ThunkDispatchType) => {
  dispatch({ type: ACTIONS.SET_PARENT_SETTINGS, payload: null });
};

export const setClientId = (clientId: string) => (dispatch: ThunkDispatchType) => {
  dispatch({ type: ACTIONS.SET_CLIENT_ID, payload: clientId });
};

export const setActiveSettingsPage = (page: number) => (dispatch: ThunkDispatchType) => {
  dispatch({ type: ACTIONS.SET_ACTIVE_SETTINGS_PAGE, payload: page });
};

export const setCustomProductChemicals = (customProductChemicals: CustomChemical[]) => (dispatch: ThunkDispatchType) => {
  dispatch({ type: ACTIONS.SET_CUSTOM_PRODUCT_CHEMICALS, payload: customProductChemicals });
};

export const setProductChemicals = (productChemicals: ProductChemicals) => (dispatch: ThunkDispatchType) => {
  dispatch({ type: ACTIONS.SET_PRODUCT_CHEMICALS, payload: productChemicals });
};
