import { getDownloadURL, ref, uploadBytes, deleteObject } from 'firebase/storage';
import { getApiData, patchApiData } from 'Services/Api';
import { CLIENT_SHOP_SETTINGS, CLIENTS, SHOP_SETTINGS } from 'Constants/apiRoutes';
import { formatApiRoute } from 'Utils/api';
import { ShopSettings } from 'Types/shopSettings.interface';
import { storage } from 'Utils/firebase';
import { PosType } from "Constants/enums";

const ShopSettingsService = {
  async getClientShopSettings(clientId: string): Promise<ShopSettings> {
    const formattedRoute = formatApiRoute({
      endpoint: CLIENT_SHOP_SETTINGS,
      params: { clientId },
      queryParams: {},
    });

    return getApiData<ShopSettings>(formattedRoute);
  },

  async getShopSettings(companyId?: string): Promise<ShopSettings> {
    const formattedRoute = formatApiRoute({
      endpoint: SHOP_SETTINGS,
      params: {},
      queryParams: companyId ? { companyId } : {},
    });

    return getApiData<ShopSettings>(formattedRoute);
  },

  // TODO: Add correct response type
  async updateShopSettings(shopSettings: Partial<ShopSettings>, clientId: string, posType: PosType) {
    const formattedRoute = formatApiRoute({
      endpoint: `${CLIENTS}/:clientId/settings/shopSettings`,
      params: { clientId },
      queryParams: { patch: true, posType },
    });

    return patchApiData(formattedRoute, shopSettings);
  },

  async uploadOnboardingBackgroundUrl(companyId: string, picture: Blob) {
    const storageRef = ref(storage, `onboarding/${companyId}/onboardingBackgroundUrl`);
    const uploadResponse = await uploadBytes(storageRef, picture);
    return getDownloadURL(uploadResponse?.ref);
  },

  async deleteOnboardingBackgroundUrl(companyId: string) {
    const storageRef = ref(storage, `onboarding/${companyId}/onboardingBackgroundUrl`);
    return deleteObject(storageRef);
  },

};

export default ShopSettingsService;
