import { ReactNode } from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';

import SendBounty from 'Containers/DefaultUserProfilePage/SendBounty';
import AttachableProducts from 'Containers/AttachableProducts';
import AttachablePromotions from 'Containers/AttachablePromotions';
import GroupAddMember from 'Containers/GroupAddMember';
import ProductReview from 'Containers/ProductReview';
import ModalSharePost from 'Components/BountyShare';
import AddGrade from 'Containers/AddGrade';
import ChangeBountyRatingValue from 'Containers/ChangeBountyRatingValue';
import ReportBounty from 'Containers/ReportBounty';
import RejectBounty from 'Containers/RejectBounty';
import DismissPost from 'Containers/DismissBounty';
import MoveBounty from 'Containers/MoveBounty';
import EndorseUser from 'Containers/EndorseUser';
import InviteLink from 'Components/InviteLink';
import PickProduct from 'Containers/PickProduct';
import EditUserDescription from 'Containers/DefaultUserProfilePage/EditDescription';
import UpdateAccountContainer from 'Containers/DefaultUserProfilePage/UpdateAccountReward';
import EditName from "Containers/DefaultUserProfilePage/EditName";
import UserProfileEditPIN from 'Components/UserProfileEditPIN';
import GroupCreate from 'Containers/GroupCreate';

import { SimpleButtonDecoration } from 'Components/SimpleButton';
import { Bounty } from 'Types/bounty.interface';
import { BountyResponse } from 'Types/bountyResponse.interface';
import { User } from 'Types/user.interface';
import { BountyType } from 'Constants/enums';
import { ExtendedGroupMember, Group } from 'Types/group.interface';
import { Reward } from "Types/reward.interface";

export const addPromotionModal = ({ closeModal }: { closeModal: () => void }) => ({
  fullWidth: true,
  maxWidth: 'sm',
  title: <FormattedMessage id="bounty.addPromotion" />,
  content: <AttachablePromotions />,
  actions: {
    buttons: [
      {
        text: <span><FormattedMessage id="button.cancel" /></span>,
        action: closeModal,
        variant: SimpleButtonDecoration.Stroke,
      },
      {
        text: <span><FormattedMessage id="button.save" /></span>,
        action: closeModal,
      },
    ],
  },
});

export const addProductModal = ({ closeModal, allowMultipleProducts }: { closeModal: () => void, allowMultipleProducts?: boolean }) => ({
  fullWidth: true,
  maxWidth: 'sm',
  title: <FormattedMessage id="title.add.product" />,
  content: <AttachableProducts allowMultiple={allowMultipleProducts} />,
  actions: {
    buttons: [
      {
        text: <span><FormattedMessage id="button.cancel" /></span>,
        action: closeModal,
        variant: SimpleButtonDecoration.Stroke,
      },
      {
        text: <span><FormattedMessage id="button.save" /></span>,
        action: closeModal,
      },
    ],
  },
});

export const addProductReview = ({ bounty }: { bounty: Bounty }) => ({
  fullScreen: false,
  fullWidth: true,
  maxWidth: 'sm',
  title: <FormattedMessage id="title.write.review" />,
  content: <ProductReview bounty={bounty} />,
});

export const createGroupModal = ({ title, group }: { title: string, group: Group | ExtendedGroupMember }) => ({
  fullWidth: true,
  maxWidth: 'sm',
  title,
  content: <GroupCreate group={group} />,
});

export const addGroupMembersModal = ({ title, group }: { title: string, group: Group | ExtendedGroupMember }) => ({
  fullWidth: true,
  maxWidth: 'sm',
  title,
  content: <GroupAddMember group={group} />,
});

export const deleteGroupModal = ({ closeModal, submitChallenge, content }: { closeModal: () => void, submitChallenge: () => void, content: ReactNode }) => ({
  fullWidth: true,
  maxWidth: 'sm',
  content,
  actions: {
    buttons: [
      {
        text: <span><FormattedMessage id="button.keepGroup" /></span>,
        action: closeModal,
        variant: SimpleButtonDecoration.Stroke,
      },
      {
        text: <span><FormattedMessage id="button.delete" /></span>,
        action: submitChallenge,
      },
    ],
  },
});

export const unfollowGroupModal = ({ content, onCancel, onSubmit }: { content: ReactNode, onCancel: () => void, onSubmit: () => void}) => ({
  fullScreen: false,
  fullWidth: true,
  maxWidth: 'sm',
  content,
  actions: {
    buttons: [
      {
        text: <span><FormattedMessage id="button.cancel" /></span>,
        action: onCancel,
        variant: SimpleButtonDecoration.Stroke,
      },
      {
        text: <span><FormattedMessage id="button.save" /></span>,
        action: onSubmit,
      },
    ],
  },
});

export const sharePostModal = ({ shareUrl, closeModal, bountyType }: {shareUrl: string, closeModal: () => void, bountyType?: BountyType}) => ({
  fullWidth: true,
  maxWidth: 'sm',
  content: <ModalSharePost postUrl={shareUrl} bountyType={bountyType!} />,
  actions: {
    buttons: [
      {
        variant: 'transparent',
        text: <span><FormattedMessage id="button.cancel" /></span>,
        action: closeModal,
      },
    ],
  },
});

export const deletePostModal = ({ bountyType, onDelete, onCancel }: { bountyType: BountyType | string, onDelete: () => void, onCancel: () => void}) => ({
  fullWidth: true,
  maxWidth: 'sm',
  title: <FormattedMessage id="title.delete" />,
  content: (
    <div>
      <FormattedMessage
        id="label.deleteBounty"
        values={{ bountyType }}
      />
    </div>
  ),
  actions: {
    buttons: [
      {
        text: <span><FormattedMessage id="button.cancel" /></span>,
        action: onCancel,
        variant: SimpleButtonDecoration.Stroke,
      },
      {
        text: <span><FormattedMessage id="button.delete" /></span>,
        action: onDelete,
      },
    ],
  },
});

export const requireAccessModal = ({
  title, message, closeModal, submitRequest,
}: {
    title: string, message: string, closeModal: () => void, submitRequest: () => void,
  }) => ({
  fullWidth: true,
  maxWidth: 'sm',
  title,
  content: <div>{message}</div>,
  actions: {
    buttons: [
      {
        text: <span><FormattedMessage id="labels.cancel" /></span>,
        action: closeModal,
        variant: SimpleButtonDecoration.Stroke,
      },
      {
        text: <span><FormattedMessage id="labels.ok" /></span>,
        action: submitRequest,
      },
    ],
  },
});

export const deleteProductModal = ({ closeModal, deleteFn }: { closeModal: () => void, deleteFn: () => void }) => ({
  maxWidth: 'sm',
  title: <FormattedMessage id="title.delete.product" />,
  content: (
    <div>
      <FormattedMessage id="content.delete.product" />
      ?
    </div>
  ),
  actions: {
    buttons: [
      {
        text: <span><FormattedMessage id="labels.cancel" /></span>,
        action: closeModal,
        variant: SimpleButtonDecoration.Stroke,
      },
      {
        text: <span><FormattedMessage id="button.delete" /></span>,
        action: deleteFn,
      },
    ],
  },
});

export const deleteAddressModal = ({ closeModal, deleteAddress }: { closeModal: () => void, deleteAddress: () => void }) => ({
  maxWidth: 'sm',
  title: <FormattedMessage id="address.deleteTitle" />,
  content: <div><FormattedMessage id="label.deleteAddressQuestion" /></div>,
  actions: {
    buttons: [
      {
        text: <span><FormattedMessage id="labels.cancel" /></span>,
        action: closeModal,
        variant: SimpleButtonDecoration.Stroke,
      },
      {
        text: <span><FormattedMessage id="button.delete" /></span>,
        action: deleteAddress,
      },
    ],
  },
});

export const addGrade = ({ bounty, response }: { bounty: Bounty, response?: BountyResponse }) => ({
  fullWidth: true,
  maxWidth: 'sm',
  title: <FormattedMessage id="title.give.rating" />,
  content: <AddGrade bounty={bounty} response={response} />,
});

export const changeRatingValueModal = ({ bounty, response }: { bounty: Bounty, response: BountyResponse}) => ({
  fullWidth: true,
  maxWidth: 'sm',
  title: <FormattedMessage id="title.change.rating" />,
  content: <ChangeBountyRatingValue response={response} bounty={bounty} />,
});

export const reportPostModal = ({ bounty, response }: { bounty: Bounty, response: BountyResponse}) => ({
  fullWidth: true,
  maxWidth: 'sm',
  title: <FormattedMessage id="label.helpUsIdentifyTheIssue" />,
  content: <ReportBounty bounty={bounty} response={response} />,
});

// TODO: make bounty optional
export const rejectPostModal = ({ bounty, response, onSuccess, onInProgressStatusChange }:
    { bounty: Bounty, response: BountyResponse, onSuccess: () => void, onInProgressStatusChange: () => boolean }) => ({
  fullWidth: true,
  maxWidth: 'sm',
  title: <FormattedMessage id="label.decline" />,
  content: <RejectBounty response={response} bounty={bounty} onSuccess={onSuccess} onInProgressStatusChange={onInProgressStatusChange} />,
});

export const dismissPostModal = ({ bounty }: { bounty: Bounty}) => ({
  fullWidth: true,
  maxWidth: 'sm',
  title: <FormattedMessage id="label.dismiss" />,
  content: <DismissPost bounty={bounty} />,
});

export const closeBountyModal = ({ submitClose, closeModal, bountyType, intl }:
  { submitClose: () => void, closeModal: () => void, bountyType: BountyType, intl: IntlShape }) => ({
  fullWidth: true,
  maxWidth: 'sm',
  title: <FormattedMessage id={`bountyAction.title.close.confirmation.${bountyType}`} defaultMessage={intl.formatMessage({ id: 'bountyAction.title.close.confirmation.post' })} />,
  content: <div><FormattedMessage id={`bountyAction.close.confirmation.${bountyType}`} defaultMessage={intl.formatMessage({ id: 'bountyAction.close.confirmation.post' })} /></div>,
  actions: {
    buttons: [
      {
        text: <span><FormattedMessage id="button.cancel" /></span>,
        action: closeModal,
        variant: SimpleButtonDecoration.Stroke,
      },
      {
        text: <span><FormattedMessage id="button.continue" /></span>,
        action: submitClose,
      },
    ],
  },
});

export const moveToListModal = ({ bounty }: { bounty: Bounty}) => ({
  fullWidth: true,
  maxWidth: 'sm',
  title: <FormattedMessage id="title.select.list" />,
  content: <MoveBounty bounty={bounty} />,
});

export const endorseUserModal = ({ user }: { user: User }) => ({
  fullScreen: false,
  fullWidth: true,
  maxWidth: 'sm',
  title: <FormattedMessage id="title.offer.endorsement.to" />,
  content: <EndorseUser user={user} />,
});

export const sendInviteModal = ({ link, closeModal }: { link: string, closeModal: () => void }) => ({
  maxWidth: 'sm',
  title: <FormattedMessage id="label.inviteLink" />,
  content: <InviteLink link={link} />,
  actions: {
    buttons: [
      {
        text: <span><FormattedMessage id="button.close" /></span>,
        action: closeModal,
      },
    ],
  },
});

export const productPickModal = ({
  getProducts,
  handleSelectProduct,
  closeModal,
  selectedProducts = [],
}: {
  getProducts: () => Promise<{ list: Bounty[], totalCount: number }>,
  handleSelectProduct: (item: Bounty) => void,
  closeModal: () => void
  selectedProducts?: Bounty[],
}) => ({
  fullWidth: true,
  maxWidth: 'sm',
  title: <FormattedMessage id="label.selectProduct" />,
  content: (
    <PickProduct
      getProducts={getProducts}
      handleSelectProduct={handleSelectProduct}
      emptyListMessage={<FormattedMessage id="products.no_product" />}
      selectedProducts={selectedProducts}
    />
  ),
  actions: {
    buttons: [
      {
        text: <span><FormattedMessage id="button.cancel" /></span>,
        action: closeModal,
      },
      // {
      //   text: <span><FormattedMessage id="button.save" /></span>,
      //   action: closeModal,
      // },
    ],
  },
});

export const updateUserDescriptionModal = {
  fullScreen: false,
  fullWidth: true,
  maxWidth: 'xs',
  title: <FormattedMessage id="modal_edit_descr_user_title" />,
  content: <EditUserDescription />,
  actions: {},
};

export const updateAccountModal = (userId: string) => ({
  fullScreen: false,
  fullWidth: true,
  maxWidth: 'sm',
  title: <FormattedMessage id="update_account_title" />,
  content: <UpdateAccountContainer userId={userId} />,
  actions: {},
});

export const updateCompanyDescriptionModal = {
  fullScreen: false,
  fullWidth: true,
  maxWidth: 'sm',
  title: <FormattedMessage id="modal_edit_descr_company_title" />,
  content: <EditUserDescription isCompany />,
  actions: {},
};

export const updatePINModal = {
  fullScreen: false,
  fullWidth: true,
  maxWidth: 'sm',
  title: <FormattedMessage id="contextmenu.editPIN" />,
  content: <UserProfileEditPIN />,
  actions: {},
};

export const sendBountiesToUserModal = ({ title }: { title: string }) => ({
  fullScreen: false,
  fullWidth: true,
  maxWidth: 'sm',
  title,
  content: <SendBounty />,
  actions: {},
});

export const unfollowUserModal = ({ username, onCancel, onSubmit }: { username: string, onCancel: () => void, onSubmit: () => void }) => ({
  fullScreen: false,
  fullWidth: true,
  maxWidth: 'sm',
  content: <div><FormattedMessage id="unfollow_user_confirmation" values={{ username }} /></div>,
  actions: {
    buttons: [
      {
        text: <span><FormattedMessage id="button.cancel" /></span>,
        action: onCancel,
        variant: SimpleButtonDecoration.Stroke,
      },
      {
        text: <span><FormattedMessage id="button.ok" /></span>,
        action: onSubmit,
      },
    ],
  },
});

export const cancelOrderModal = ({ onCancel, onSubmit }: { onCancel: () => void, onSubmit: () => void }) => ({
  maxWidth: 'sm',
  title: <FormattedMessage id="label.cancelOrder" />,
  content: <FormattedMessage id="label.cancelOrderQuestion" />,
  actions: {
    buttons: [
      {
        text: <span><FormattedMessage id="button.cancel" /></span>,
        action: onCancel,
        variant: SimpleButtonDecoration.Stroke,
      },
      {
        text: <span><FormattedMessage id="button.submit" /></span>,
        action: onSubmit,
      },
    ],
  },
});

export const completeOrderModal = ({ onCancel, onSubmit }: { onCancel: () => void, onSubmit: () => void }) => ({
  maxWidth: 'sm',
  title: <FormattedMessage id="label.completeOrder" />,
  content: <FormattedMessage id="label.completeOrderQuestion" />,
  actions: {
    buttons: [
      {
        text: <span><FormattedMessage id="button.cancel" /></span>,
        action: onCancel,
        variant: SimpleButtonDecoration.Stroke,
      },
      {
        text: <span><FormattedMessage id="button.submit" /></span>,
        action: onSubmit,
      },
    ],
  },
});

export const tooManyResentVerificationRequestsModal = ({ onClose }: { onClose: () => void }) => ({
  fullWidth: true,
  maxWidth: 'sm',
  title: <FormattedMessage id="label.headsUp" />,
  content: (
    <FormattedMessage id="label.tooManyResentVerificationRequests" />
  ),
  actions: {
    buttons: [
      {
        text: <span><FormattedMessage id="button.ok" /></span>,
        action: onClose,
      },
    ],
  },
});

export const insufficientTokenAmountModal = (insufficientAmount: Reward, onClose: () => void) => ({
  fullScreen: false,
  fullWidth: true,
  maxWidth: 'sm',
  title: <FormattedMessage id="label.completeOrder" />,
  content: (
    <div>
      <p>
        <FormattedMessage id="label.toCompleteOrderNeed" />
        {':'}
      </p>

      {insufficientAmount?.points && (
        <ul className="ml-20 mb-0">
          {Object.values(insufficientAmount?.points)?.map(({ amount, currency }) => (
            <li key={currency}>
              <p className="font-weight-bold">{`${amount} ${currency}`}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  ),
  actions: {
    buttons: [
      {
        text: <span><FormattedMessage id="button.close" /></span>,
        action: onClose,
      },
    ],
  },
});

export const updateUserNameModal = {
  fullScreen: false,
  fullWidth: true,
  maxWidth: 'xs',
  title: <FormattedMessage id="label.editName" />,
  content: <EditName />,
  actions: {},
};

export const saveProductChangesModal = ({ onCancel, onSave }: { onCancel: () => void; onSave: () => void }) => ({
  fullWidth: true,
  maxWidth: 'sm',
  title: <FormattedMessage id="label.productChanges" />,
  content: (
    <FormattedMessage id="label.saveChangesToProduct" />
  ),
  actions: {
    buttons: [
      {
        text: <span><FormattedMessage id="button.cancel" /></span>,
        action: onCancel,
        variant: SimpleButtonDecoration.Stroke,
      },
      {
        text: <span><FormattedMessage id="button.yes" /></span>,
        action: onSave,
      },
    ],
  },
});

export const deleteCreditCardModal = ({ onCancel, onSave }: { onCancel: () => void; onSave: () => void }) => ({
  fullWidth: true,
  maxWidth: 'sm',
  title: <FormattedMessage id="label.removePaymentMethod" />,
  content: (
    <FormattedMessage id="label.areYouSureRemovePayment" />
  ),
  actions: {
    buttons: [
      {
        text: <span><FormattedMessage id="button.cancel" /></span>,
        action: onCancel,
        variant: SimpleButtonDecoration.Stroke,
      },
      {
        text: <span><FormattedMessage id="button.remove" /></span>,
        action: onSave,
      },
    ],
  },
});

export const deleteLocationModal = ({ closeModal, deleteFn }: { closeModal: () => void, deleteFn: () => void }) => ({
  maxWidth: 'sm',
  title: <FormattedMessage id="title.delete.location" />,
  content: <FormattedMessage id="content.delete.location" />,
  actions: {
    buttons: [
      {
        text: <span><FormattedMessage id="labels.cancel" /></span>,
        action: closeModal,
        variant: SimpleButtonDecoration.Stroke,
      },
      {
        text: <span><FormattedMessage id="button.delete" /></span>,
        action: deleteFn,
      },
    ],
  },
});
