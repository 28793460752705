import { ConfigCommon } from '../../../src/types/config.interface';
import { TabsStyle } from '../../../src/constants/enums';

const config: ConfigCommon = {
  id: 'campaigns',
  title: 'Campaigns',
  name: 'Campaigns app',
  brandName: 'Campaigns',
  appLinkSchema: 'campaigns',
  appTld: 'getcampaigns.com',
  useCase: 'campaigns',
  tags: ['all', 'active'],
  auth: {
    enableEmailVerification: true,
    enableOnboarding: true,
    disablePinFlow: true,
    defaultPin: '1515',
    disabledPins: ['101'],
    customCss: 'smallLogo',
  },
  customCss: {
    sidebarLogo: 'svgLogo',
    tabs: TabsStyle.Pills,
  },
  customPages: {
    home: 'AsyncCampaignsHomePage',
  },
  custom: {
    viewBaseUrl: 'https://view.getcampaigns.com',
    campaignTestUrl: 'https://campaigns-view--test-lwl2397x.web.app',
    campaignIOS: 'https://apps.apple.com/us/app/campaigns-boost-engagement/id6466628652',
    stripeDashboardUrl: 'https://dashboard.stripe.com/',
    isLanguageSelectorHidden: true,
  },
  TERMS_OF_SERVICE_URL: 'https://getcampaigns.com/tos',
  PRIVACY_STATEMENT_URL: 'https://getcampaigns.com/privacy',
};

export default config;
