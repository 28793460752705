import { getOptionItemName } from 'Utils/productOptions';
import { formatReward, multiply } from 'Utils/currency';
import { add } from 'Utils/models/Reward';
import { ProductInfo } from 'Types/productInfo.interface';
import { EMPTY_REWARD } from 'Constants/currency';
import { Reward } from 'Types/reward.interface';
import { ShoppingCart } from 'Types/cart.interface';
import { DeliveryType, OrderSectionType } from 'Constants/enums';
import { OrderSummaryResult } from 'Types/orderSummaryResult.interface';
import { Endorsement } from 'Types/endorsement.interface';
import { Bounty } from 'Types/bounty.interface';
import { User } from 'Types/user.interface';
import { ProductSpecificInfo } from 'Types/product.interface';
import { Price } from 'Types/price.interface';
import { getEffectiveVariant } from 'Utils/product';

export const valueOfProductInfo = (params: { productBounty: Bounty, sku: string | null }): ProductInfo => {
  const { productBounty, sku } = params;
  const { product } = productBounty;
  const productFlavor = getEffectiveVariant(product, sku) as ProductSpecificInfo;
  const { price, onlineProductInfo } = productFlavor;

  return {
    id: '',
    bountyId: productBounty.id || '',
    merchant: product?.merchant || {} as User,
    merchantId: product?.merchantId || '',
    productSku: productFlavor?.sku || null,
    opt1Name: getOptionItemName(product?.options, 1, productFlavor?.optionKey1),
    opt2Name: getOptionItemName(product?.options, 2, productFlavor?.optionKey2),
    opt3Name: getOptionItemName(product?.options, 3, productFlavor?.optionKey3),
    name: product?.name || '',
    description: product?.description || '',
    kind: product?.kind || '',
    imageUrl: product?.imageUrl || '',
    msrp: productFlavor?.msrp || {} as Price,
    deliveryType: product?.deliveryType as DeliveryType || DeliveryType.Unknown,
    visibility: productBounty?.visibility,
    quantity: 1,
    addedAt: new Date().getTime(),
    price: price.mainPrice || null,
    onlineProductInfo: onlineProductInfo || null,
  };
};

export function calculateCartTotal(products: Record<string, ProductInfo>) {
  let total: Reward | null = null;

  Object.values(products).forEach(({ price, quantity }) => {
    if (price) {
      total = total
        ? add(total, multiply(price, quantity))
        : multiply(price, quantity);
    }
  });

  return total ? formatReward(total, EMPTY_REWARD) : EMPTY_REWARD;
}

export const populateProductRating = (reviews: Record<string, Endorsement>) => {
  const reviewCounter = Object.keys(reviews).length;
  let total = 0;

  if (!reviewCounter) {
    return 0;
  }

  Object.values(reviews).forEach((review) => {
    if (review && review.reward && review.reward.points) {
      Object.values(review.reward.points).forEach((point) => {
        total += point.amount || 0;
      });
    }
  });

  return total / reviewCounter;
};

export const getShoppingCartPrices = (shoppingCart: ShoppingCart | null) => {
  const rewards: Reward[] = [];

  if (!shoppingCart || !shoppingCart.products || !Object.keys(shoppingCart.products).length) {
    return null;
  }

  Object.values(shoppingCart.products).forEach((product) => {
    const reward = product.price ? multiply(product.price, product.quantity) : null;

    if (reward) {
      rewards.push(reward);
    }
  });

  return rewards;
};

export const getFirstNonNullSection = (summary: OrderSummaryResult, types: OrderSectionType[]) => {
  if (!summary || !summary.summary) {
    return null;
  }

  const section = types.find((type) => summary.summary.sections[type]) || null;

  if (section) {
    return summary.summary.sections[section];
  }

  return null;
};
