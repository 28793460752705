import { ReservationStatus, OrderStatus } from 'Constants/enums';

export const RESERVATION_STATUS_COLOR = {
  [ReservationStatus.NotConfirmed]: '#D3D3D3',
  [ReservationStatus.LeftMessage]: '#ADD8E6',
  [ReservationStatus.Confirmed]: '#98FB98',
  [ReservationStatus.Late]: '#FFA07A',
  [ReservationStatus.PartiallyArrived]: '#FFFFE0',
  [ReservationStatus.AllArrived]: '#9ACD32',
  [ReservationStatus.PartiallySeated]: '#FFE5B4',
  [ReservationStatus.Seated]: '#EE82EE',
  [ReservationStatus.Appetizer]: '#FFA07A',
  [ReservationStatus.Entree]: '#AFEEEE',
  [ReservationStatus.Dessert]: '#E6E6FA',
  [ReservationStatus.CheckDropped]: '#F08080',
  [ReservationStatus.Paid]: '#98FF98',
  [ReservationStatus.BusTable]: '#F5F5DC',
  [ReservationStatus.Done]: '#FAFAD2',
  [ReservationStatus.NoShow]: '#C0C0C0',
  [ReservationStatus.Cancelled]: '#FFC0CB',
  [ReservationStatus.AssumedSeated]: '#E0FFFF',
  [ReservationStatus.AssumedDone]: '#FDEEF4',
};

export const ORDER_STATUS_COLOR = {
  [OrderStatus.None]: '#F0F0F0',
  [OrderStatus.New]: '#ADD8E6',
  [OrderStatus.Proposed]: '#F9EAD5',
  [OrderStatus.Payed]: '#DFF0D8',
  [OrderStatus.Pending]: '#FDEBD0',
  [OrderStatus.PendingPayment]: '#FDEBD0',
  [OrderStatus.Processing]: '#FFDAB9',
  [OrderStatus.ReadyForPickup]: '#E3EAF5',
  [OrderStatus.Shipped]: '#D8BFD8',
  [OrderStatus.MerchReceived]: '#D0E9C6',
  [OrderStatus.Settled]: '#DFF0D8',
  [OrderStatus.Chargeback]: '#FDEBD0',
  [OrderStatus.Disputed]: '#F9E2D2',
  [OrderStatus.PaymentFailed]: '#ff9999',
  [OrderStatus.Negotiation]: '#F9EAD5',
  [OrderStatus.Completed]: '#90EE90',
  [OrderStatus.Declined]: '#F9E2D2',
  [OrderStatus.Cancelled]: '#F0F0F0',
  [OrderStatus.Unknown]: '#F0F0F0',
};

export const AVATAR_COLORS = ['#6A6E75', '#BAA892', '#8A8D61', '#91877D', '#4C6374'];
