import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, DialogActions, IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import SimpleButton from 'Components/SimpleButton';

import * as modalSelectors from 'Store/modal/selectors';

import { closeModal } from 'Store/modal/actions';
import { clearDataSyncAction } from 'Store/genericActions';
import { GenericReducerName } from 'Constants/genericReducerName';
import { GenericReducerProp } from 'Constants/genericReducerProp';

import * as styles from './index.module.scss';

const CustomModal = () => {
  const dispatch = useDispatch();
  const settings = useSelector(modalSelectors.settings);
  const isLoading = useSelector(modalSelectors.isLoading);

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick' && !settings.backdrop) {
      return;
    }

    dispatch(closeModal());
    dispatch(clearDataSyncAction(GenericReducerName.Modal, GenericReducerProp.ModalSettings));
  };

  return (
    <Dialog
      fullScreen={settings.fullScreen}
      fullWidth={settings.fullWidth}
      maxWidth={settings.maxWidth}
      open={!!settings.isOpen}
      aria-labelledby="max-width-dialog-title"
      onClose={handleClose}
      PaperProps={{ className: !!settings?.isOpen ? styles.paper : styles.hidden }}
    >
      <>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className={styles.closeButton}
        >
          <span className={`icon-add ${styles.closeIcon}`} />
        </IconButton>
        {!!settings.title && (
          <DialogTitle id="form-dialog-title" className={styles.dialogTitle}>
            {settings.title}
          </DialogTitle>
        )}
        <DialogContent className={styles.dialogContent}>
          <div className={isLoading ? styles.opacity : ''}>
            {settings.content}
          </div>
          {isLoading && <CircularProgress size={24} className={styles.buttonProgress} />}
        </DialogContent>
        {settings.actions && settings.actions.buttons && (
          <DialogActions className={styles.dialogActions}>
            {
              settings.actions.buttons.map(({
                action, text, disabled, variant, ...remainingProps
              }, index) => {
                const isDisabled = disabled;

                return (
                  <SimpleButton
                    {...remainingProps}
                    key={`action-button-${index}`}
                    decoration={variant}
                    label={text}
                    onClick={action}
                    isLoading={isLoading}
                    className={isDisabled ? styles.opacity : ''}
                  />
                );
              })
            }
          </DialogActions>
        )}
      </>
    </Dialog>
  );
};

export default CustomModal;
