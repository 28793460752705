import { getApplicationConfig } from 'Utils/appConfig';
import { CompanyType, UserRole } from 'Constants/enums';
import { ADMIN_PIN } from 'Constants/common';
import { ApplicationConfig } from 'Types/applicationConfig.type';
import { UserData, UserInfo } from 'Types/userData.interface';
import { parseRolesList } from 'Utils/roleDef';

export enum UseCases {
  shop = 'shop',
  vera = 'vera',
  campaigns = 'campaigns',
  vip = 'vip',
  dinamo = 'dinamo',
}

export enum AllowedOpTypes {
  canCreateBounty = 'canCreateBounty',
  canSeeSents = 'canSeeSents',
  canUploadProducts = 'canUploadProducts',
  canCreateJobs = 'canCreateJobs',
  canSeeJobs = 'canSeeJobs',
  canNotify = 'canNotify',
  canSeePromotions = 'canSeePromotions',
  canCreateBadge = 'canCreateBadge',
  canSeeAiBot = 'canSeeAiBot',
  canSeeReviews = 'canSeeReviews',
  canSeeDocSearch = 'canSeeDocSearch',
  canSeeTokenPackages = 'canSeeTokenPackages',
  canSeeReports = 'canSeeReports',
  canSeeLeaderBoard = 'canSeeLeaderBoard',
  canSeeMarket = 'canSeeMarket',
  canSeeOrderHistory = 'canSeeOrderHistory',
  canSeeCart = 'canSeeCart',
  canSeeMyAccount = 'canSeeMyAccount',
  canSeeCompanies = 'canSeeCompanies',
  canSeeResponses = 'canSeeResponses',
  canSeeRecvOrders = 'canSeeRecvOrders',
  canSeeGroups = 'canSeeGroups',
  canSeeApplicants = 'canSeeApplicants',
  canUpdateAccount = 'canUpdateAccount',
  canSeeBlackList = 'canSeeBlackList',
  canCreateOrganization = 'canCreateOrganization',
  canEditProfile = 'canEditProfile',
  canSeeShell = 'canSeeShell',
  canSeeUsers = 'canSeeUsers',
  canSeeCompany = 'canSeeCompany',
  canSeeMatch = 'canSeeMatch',
  canHaveManyInviteLinks = 'canHaveManyInviteLinks',
  canSeeLocations = 'canSeeLocations',
  canCreateLocation = 'canCreateLocation',
  canSeeCompanyMembers = 'canSeeCompanyMembers',
  canSeeClients = 'canSeeClients',
  canSeeShopSettings = 'canSeeShopSettings',
  canSeeBanners = 'canSeeBanners',
  canCreateBanners = 'canCreateBanners',
  canSeeAnalytics = 'canSeeAnalytics',
  canSeeMessages = 'canSeeMessages',
  canTalkToSupport = 'canTalkToSupport',
  canSeeCustomTokens = 'canSeeCustomTokens',
  canCreateTokenPack = 'canCreateTokenPack',
  canSeeMyProducts = 'canSeeMyProducts',
  canSeeUserManagement = 'canSeeUserManagement',
  canSeeOrderNotifications = 'canSeeOrderNotifications',
  canSeeShop = 'canSeeShop',
  canLockClient = 'canLockClient',
  canSeeCompanyAccount = 'canSeeCompanyAccount',
  canSeeReservations = 'canSeeReservations',
  canSeeKioskSettings = 'canSeeKioskSettings',
  canSeeSiteSettings = 'canSeeSiteSettings',
  canMarkAsSupport = 'canMarkAsSupport',
  canCreateCampaign = 'canCreateCampaign',
  canSeeCampaigns = 'canSeeCampaigns'
}

type SysAdminPermissions = {
  [key in UseCases]?: {
    [key in UserRole]?: {
      [key in AllowedOpTypes]?: boolean;
    }
  }
}

const SYS_ADMIN_PERMISSIONS: SysAdminPermissions = {
  [UseCases.shop]: {
    [UserRole.User]: {
      [AllowedOpTypes.canSeeCompanyMembers]: true,
      [AllowedOpTypes.canSeeClients]: true,
      [AllowedOpTypes.canSeeMessages]: true,
      [AllowedOpTypes.canSeeReports]: true,
      [AllowedOpTypes.canLockClient]: true,
    },
    [UserRole.Admin]: {
      [AllowedOpTypes.canSeeCompanyMembers]: true,
      [AllowedOpTypes.canSeeClients]: true,
      [AllowedOpTypes.canSeeMessages]: true,
      [AllowedOpTypes.canSeeReports]: true,
      [AllowedOpTypes.canLockClient]: true,
      [AllowedOpTypes.canSeeSiteSettings]: true,
      [AllowedOpTypes.canMarkAsSupport]: true,
      [AllowedOpTypes.canCreateLocation]: true,
    },
  },
  [UseCases.vera]: {
    [UserRole.User]: {
      [AllowedOpTypes.canSeeDocSearch]: true,
      [AllowedOpTypes.canSeeAiBot]: true,
    },
    [UserRole.Admin]: {
      [AllowedOpTypes.canSeeDocSearch]: true,
      [AllowedOpTypes.canSeeAiBot]: true,
    },
  },
  [UseCases.campaigns]: {
    [UserRole.User]: {
      [AllowedOpTypes.canSeeTokenPackages]: true,
      [AllowedOpTypes.canSeeOrderHistory]: true,
      [AllowedOpTypes.canSeeCart]: true,
      [AllowedOpTypes.canSeeMessages]: true,
      [AllowedOpTypes.canSeeMyAccount]: true,
      [AllowedOpTypes.canSeeCustomTokens]: true,
      [AllowedOpTypes.canSeeShop]: true,
      [AllowedOpTypes.canSeeCampaigns]: true,
      [AllowedOpTypes.canCreateCampaign]: true,
    },
    [UserRole.Admin]: {
      [AllowedOpTypes.canSeeTokenPackages]: true,
      [AllowedOpTypes.canSeeOrderHistory]: true,
      [AllowedOpTypes.canSeeCart]: true,
      [AllowedOpTypes.canSeeMessages]: true,
      [AllowedOpTypes.canSeeMyAccount]: true,
      [AllowedOpTypes.canSeeCustomTokens]: true,
      [AllowedOpTypes.canSeePromotions]: true,
      [AllowedOpTypes.canCreateTokenPack]: true,
      [AllowedOpTypes.canSeeShop]: true,
      [AllowedOpTypes.canSeeCompanyAccount]: true,
      [AllowedOpTypes.canSeeCampaigns]: true,
      [AllowedOpTypes.canCreateCampaign]: true,
    },
  },
  [UseCases.vip]: {
    [UserRole.User]: {
      [AllowedOpTypes.canSeeMessages]: true,
      [AllowedOpTypes.canSeeMyAccount]: true,
    },
    [UserRole.Admin]: {
      [AllowedOpTypes.canSeeMessages]: true,
      [AllowedOpTypes.canSeeMyAccount]: true,
      [AllowedOpTypes.canSeePromotions]: true,
      [AllowedOpTypes.canSeeLeaderBoard]: true,
      [AllowedOpTypes.canSeeReports]: true,
      [AllowedOpTypes.canSeeMyProducts]: true,
      [AllowedOpTypes.canCreateBadge]: true,
      [AllowedOpTypes.canSeeGroups]: true,
      [AllowedOpTypes.canSeeClients]: true,
      [AllowedOpTypes.canCreateLocation]: true,
    },
  },
};

type OrgPermissions = {
  [key in UseCases]?: {
    [key in CompanyType]?: {
      [key in UserRole]?: {
        [key in AllowedOpTypes]?: boolean;
      };
    };
  }
}

const ORG_PERMISSIONS: OrgPermissions = {
  [UseCases.shop]: {
    [CompanyType.Corporate]: {
      [UserRole.User]: {
        [AllowedOpTypes.canSeeLocations]: true,
        [AllowedOpTypes.canSeeCompanyMembers]: true,
        [AllowedOpTypes.canSeeBanners]: true,
        [AllowedOpTypes.canSeeRecvOrders]: true,
        [AllowedOpTypes.canSeeMessages]: true,
      },
      [UserRole.Admin]: {
        [AllowedOpTypes.canSeeShopSettings]: true,
        [AllowedOpTypes.canSeeLocations]: true,
        [AllowedOpTypes.canSeeCompanyMembers]: true,
        [AllowedOpTypes.canCreateBanners]: true,
        [AllowedOpTypes.canSeeBanners]: true,
        [AllowedOpTypes.canSeeRecvOrders]: true,
        [AllowedOpTypes.canSeeMessages]: true,
        [AllowedOpTypes.canSeeReports]: true,
        [AllowedOpTypes.canSeeKioskSettings]: true,
        [AllowedOpTypes.canMarkAsSupport]: true,
        [AllowedOpTypes.canCreateLocation]: true,
        // [AllowedOpTypes.canSeeReviews]: true,
      },
    },
    [CompanyType.Merchant]: {
      [UserRole.User]: {
        [AllowedOpTypes.canSeeCompanyMembers]: true,
        [AllowedOpTypes.canSeeBanners]: true,
        [AllowedOpTypes.canSeeRecvOrders]: true,
        [AllowedOpTypes.canSeeMessages]: true,
        [AllowedOpTypes.canSeeOrderNotifications]: true,
      },
      [UserRole.Admin]: {
        [AllowedOpTypes.canSeeShopSettings]: true,
        [AllowedOpTypes.canSeeCompanyMembers]: true,
        [AllowedOpTypes.canCreateBanners]: true,
        [AllowedOpTypes.canSeeBanners]: true,
        [AllowedOpTypes.canSeeRecvOrders]: true,
        [AllowedOpTypes.canSeeMessages]: true,
        [AllowedOpTypes.canSeeReports]: true,
        [AllowedOpTypes.canSeeOrderNotifications]: true,
        [AllowedOpTypes.canSeeKioskSettings]: true,
        [AllowedOpTypes.canMarkAsSupport]: true,
        // [AllowedOpTypes.canSeeReviews]: true,
      },
    },
  },
  [UseCases.vera]: {
    [CompanyType.Vulgus]: {
      [UserRole.User]: {
        [AllowedOpTypes.canSeeDocSearch]: true,
        [AllowedOpTypes.canSeeAiBot]: true,
        [AllowedOpTypes.canSeeMessages]: false,
      },
      [UserRole.Admin]: {
        [AllowedOpTypes.canSeeDocSearch]: true,
        [AllowedOpTypes.canSeeAiBot]: true,
        [AllowedOpTypes.canSeeMessages]: false,
      },
    },
  },
  [UseCases.campaigns]: {
    [CompanyType.Vulgus]: {
      [UserRole.User]: {
        [AllowedOpTypes.canSeeTokenPackages]: true,
        [AllowedOpTypes.canSeeOrderHistory]: true,
        [AllowedOpTypes.canSeeCart]: true,
        [AllowedOpTypes.canSeeMessages]: true,
        [AllowedOpTypes.canTalkToSupport]: true,
        [AllowedOpTypes.canSeeMyAccount]: true,
        [AllowedOpTypes.canSeeCustomTokens]: true,
        [AllowedOpTypes.canSeeShop]: true,
        [AllowedOpTypes.canSeeCampaigns]: true,
        [AllowedOpTypes.canCreateCampaign]: true,
      },
      [UserRole.Admin]: {
        [AllowedOpTypes.canSeeTokenPackages]: true,
        [AllowedOpTypes.canSeeOrderHistory]: true,
        [AllowedOpTypes.canSeeCart]: true,
        [AllowedOpTypes.canSeeMessages]: true,
        [AllowedOpTypes.canSeeMyAccount]: true,
        [AllowedOpTypes.canSeeCustomTokens]: true,
        [AllowedOpTypes.canSeePromotions]: true,
        [AllowedOpTypes.canCreateTokenPack]: true,
        [AllowedOpTypes.canSeeShop]: true,
        [AllowedOpTypes.canSeeCampaigns]: true,
        [AllowedOpTypes.canCreateCampaign]: true,
      },
      [UserRole.Operator]: {
        [AllowedOpTypes.canSeeTokenPackages]: true,
        [AllowedOpTypes.canSeeOrderHistory]: true,
        [AllowedOpTypes.canSeeCart]: true,
        [AllowedOpTypes.canSeeMessages]: true,
        [AllowedOpTypes.canSeeMyAccount]: true,
        [AllowedOpTypes.canSeeCustomTokens]: true,
        [AllowedOpTypes.canSeeShop]: true,
        [AllowedOpTypes.canSeeCampaigns]: true,
        [AllowedOpTypes.canCreateCampaign]: true,
      },
    },
  },
  [UseCases.vip]: {
    [CompanyType.Vulgus]: {
      [UserRole.User]: {
        [AllowedOpTypes.canSeeMessages]: true,
        [AllowedOpTypes.canSeeMyAccount]: true,
      },
      [UserRole.Operator]: {
        [AllowedOpTypes.canSeeMessages]: true,
        [AllowedOpTypes.canSeeMyAccount]: true,
        [AllowedOpTypes.canSeePromotions]: true,
        [AllowedOpTypes.canSeeLeaderBoard]: true,
        [AllowedOpTypes.canSeeReports]: true,
        [AllowedOpTypes.canSeeMyProducts]: true,
        [AllowedOpTypes.canCreateBadge]: true,
        [AllowedOpTypes.canSeeGroups]: true,
        [AllowedOpTypes.canSeeSents]: true,
        [AllowedOpTypes.canCreateBounty]: true,
      },
    },
    [CompanyType.Corporate]: {
      [UserRole.User]: {
        [AllowedOpTypes.canSeeMessages]: true,
        [AllowedOpTypes.canSeeLocations]: true,
        [AllowedOpTypes.canSeeReservations]: true,
      },
      [UserRole.Admin]: {
        [AllowedOpTypes.canSeeMessages]: true,
        [AllowedOpTypes.canSeeLocations]: true,
        [AllowedOpTypes.canCreateLocation]: true,
        [AllowedOpTypes.canSeeReservations]: true,
        [AllowedOpTypes.canSeeCompanyMembers]: true,
      },
    },
    [CompanyType.Restaurant]: {
      [UserRole.User]: {
        [AllowedOpTypes.canSeeMessages]: true,
        [AllowedOpTypes.canSeeReservations]: true,
      },
      [UserRole.Admin]: {
        [AllowedOpTypes.canSeeMessages]: true,
        [AllowedOpTypes.canSeeReservations]: true,
        [AllowedOpTypes.canSeeCompanyMembers]: true,
      },
    },
  },
};

type GlobalPermissionsProps = {
  userData: { data: UserData, isLoading: boolean };
  companySettings: any; // TODO: add the type for companySettings
  userInfo: UserInfo;
};

export const getGlobalPermissions = ({ companySettings, userData, userInfo }: GlobalPermissionsProps) => {
  const { useCase }: ApplicationConfig = getApplicationConfig();
  const useCaseKey = useCase as UseCases;

  //  If no useCase defined, fallback to the existing permissions check
  if (!useCaseKey) {
    return null;
  }

  const hasAdminPin = userInfo?.clientId === ADMIN_PIN;
  const orgType: CompanyType = companySettings?.organizationType;
  const userRoles = parseRolesList(userData?.data?.roles);
  const userRole = userRoles?.some((role) => role === UserRole.Admin)
    ? UserRole.Admin
    : userRoles?.[0] as UserRole || UserRole.User;

  if (hasAdminPin && userRole) {
    return SYS_ADMIN_PERMISSIONS[useCaseKey]?.[userRole] || null;
  }

  if (ORG_PERMISSIONS[useCaseKey] && userRole) {
    return ORG_PERMISSIONS[useCaseKey]?.[orgType]?.[userRole] || null;
  }

  return null;
};
