import { Breakpoint } from '@mui/system';
import { ThunkAction, AnyAction } from '@reduxjs/toolkit';
import { GenericReducerName } from 'Constants/genericReducerName';
import { GenericReducerProp } from 'Constants/genericReducerProp';
import { ReactNode } from 'react';
import { setDataAction, updateDataSyncAction, updateSimpleDataSyncAction } from 'Store/genericActions';
import { RootState, ThunkDispatchType } from 'Types/redux.types';
import { SimpleButtonDecoration } from "Components/SimpleButton";

type ThunkResult<R> = ThunkAction<R, RootState, undefined, AnyAction>;

interface Button {
  text: ReactNode;
  action: () => void;
  variant?: SimpleButtonDecoration;
}

interface Actions {
  buttons?: Button[];
}

interface OpenModalPayload {
  fullWidth?: boolean,
  backdrop?: boolean,
  maxWidth?: Breakpoint | string,
  title?: ReactNode | string,
  content: ReactNode,
  actions?: Actions,
}

export const closeModal = () => (dispatch: ThunkDispatchType) => (
  dispatch(setDataAction(GenericReducerName.Modal, GenericReducerProp.ModalSettings, { isOpen: false, content: null })));

export const openModal = (payload: OpenModalPayload): ThunkResult<void> => (dispatch: ThunkDispatchType) => {
  dispatch(updateDataSyncAction(GenericReducerName.Modal, GenericReducerProp.ModalSettings, { isOpen: true, ...payload }));
};

export const setIsLoading = (isLoading: boolean) => (dispatch: ThunkDispatchType) => {
  dispatch(updateSimpleDataSyncAction(GenericReducerName.Modal, GenericReducerProp.ModalIsLoading, isLoading));
};
