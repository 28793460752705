import { FormattedMessage } from 'react-intl';
import {
  ApplicationJourneyPhaseStatus,
  ApplicationStatus,
  AuthProvider,
  Benefit,
  BonusType,
  EnvironmentMode,
  Gender,
  DocumentType,
  SpecialProgramSpecialty,
  PayPeriodUnit,
  PlatformLabels,
  SalaryType,
  UserReputation,
  UserRole,
  WeightUnit,
  VaccinationStatus,
  VaccinationVendor,
  WorkExperienceDuration,
  WorkExperienceLevel,
  UserAuthStatus,
  BannerType,
  TargetApp,
  ConfirmationModalType,
  ClientStatus,
  BountyState,
  AuditVerb,
  ApplicationJourneyPhase,
  AttachmentAction,
  AgeFilterType,
  AnonUsersType,
  MedicalIdPromptType,
  CustomerBalanceType,
  DeliveryMethod,
  OrderStatus,
  PaymentMethod,
  HoursOfOperationType,
  OperationStatus,
  DayOfWeekType,
  ShopStatusType,
  AnalyticsDateType,
  InviteeRoles,
  ChatStatus,
  LandingPageType,
  GroupType,
  InvitationStatus,
  ReportType,
  TemplateType,
  CheckoutFieldType,
  CustomCurrencyStatus,
  TerminalPosition,
  ReservationStatus,
  ReservationAttribute,
  ProductSortOptions,
  ViewMode,
  TribalIdMode,
  AddressAutocomplete,
  ChemicalViewMode,
  ChemicalFilters,
  PolicyType,
  ReportReasonTypes,
  ChemicalKind,
  SupportChat,
  SEOType,
  Scheme,
  MerchantOrderStatus,
  DeliveryFeeSource,
  FlwrQuantityVariant, StartButtonPosition, PosType
} from 'Constants/enums';
import { underscoreCaseToCamelCase } from 'Utils/string';
import {
  BONUS_PAYMENT_PERIOD_UNITS_TRANSLATION_KEYS,
  PAYMENT_PERIOD_UNITS_TRANSLATION_KEYS
} from 'Constants/jobUnit';
import { DictionaryItem } from 'Types/dictionaryItem.type';

/**
 * Generate options from enum
 *
 * @param {Object} enumObj Enum
 * @param {Function=} formatFunction Function that format translation from the enum value
 * @param {boolean} ignoreTranslation Flag that determine should we skip using returned value as translation key
 *
 * @returns {Array} DictionaryItem[]
 */
export const getOptionsFromEnum = (enumObj: object, formatFunction?: (v: any) => string, ignoreTranslation: boolean = false): DictionaryItem<any>[] =>
  Object.values(enumObj)
    .map(((value) => {
      const formattedValue = formatFunction ? formatFunction(value) : value;

      return {
        label: ignoreTranslation ? formattedValue : <FormattedMessage id={formattedValue} />,
        value,
      };
    }));

/**
 * Getting translation key based on {platformLabel}
 *
 * @param {string} platformLabel platform name
 *
 * @returns {string} translation key
 */

export const getPlatformNameTranslationKey = (platformLabel: string): string => {
  switch (platformLabel) {
    case PlatformLabels.Console:
      return 'label.fe.consoleWeb';
    case PlatformLabels.JoinWeb:
      return 'label.fe.joinWeb';
    case PlatformLabels.Android:
      return 'label.fe.androidApp';
    case PlatformLabels.IOS:
      return 'label.fe.iosApp';
    default:
      return 'label.fe.na';
  }
};

export const getGenderTranslationKey = (value: Gender) =>
  (value ? `label.gender.${value.toLowerCase()}` : '');

export const getStageTranslationKey = (value: Gender) =>
  (value ? `label.profileStage.${value.toLowerCase()}` : '');

export const getApplicationJourneyPhaseStatusTranslationKey = (value: ApplicationJourneyPhaseStatus) =>
  `label.applicationJourneyPhaseStatus.${value}`;

export const getApplicationHrStatusTranslationKey = (value: ApplicationJourneyPhaseStatus) =>
  `label.applicationHrStatus.${value}`;

export const getGroupTypeTranslationKey = (value: GroupType) =>
  `label.group.${value}`;

export const getUserRoleTranslationKey = (value: UserRole) => `label.role.${value}`;

const capitalizeString = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const getAuthProviderTranslationKey = (value: AuthProvider) => capitalizeString(value);

export const getApplicationStatusTranslationKey = (value: ApplicationStatus) =>
  `label.userStatus.${underscoreCaseToCamelCase(value.toLowerCase())}`;

export const getSalaryTypeTranslationKey = (value: SalaryType) => {
  switch (value) {
    case SalaryType.Net:
      return 'label.salaryType.net';
    case SalaryType.Gross:
      return 'label.salaryType.gross';
    case SalaryType.Budget:
    default:
      return 'label.salaryType.budget';
  }
};

export const getWorkExperienceDurationTranslationKey = (value: WorkExperienceDuration) =>
  `work_exp.duration.${value}`;

export const getWorkExperienceLevelTranslationKey = (value: WorkExperienceLevel) =>
  `work_exp.level.${value}`;

export const getBenefitTranslationKey = (value: Benefit) => {
  switch (value) {
    case Benefit.None:
      return 'label.none';
    case Benefit.Unknown:
      return 'label.unknown';
    default:
      return `benefits.label.${value}`;
  }
};

export const getBonusTypeTranslationKey = (value: BonusType) => {
  switch (value) {
    case BonusType.Loyalty:
      return 'label.bonus.loyalty';
    case BonusType.Project:
      return 'label.bonus.project';
    case BonusType.Other:
      return 'label.bonus.other';
    default:
      return 'label.unknown';
  }
};

export const getBonusPayPeriodUnitTranslationKey = (value: PayPeriodUnit) =>
  BONUS_PAYMENT_PERIOD_UNITS_TRANSLATION_KEYS[value] || 'label.unknown';

export const getPaymentPeriodUnitTranslationKey = (value: PayPeriodUnit) =>
  PAYMENT_PERIOD_UNITS_TRANSLATION_KEYS[value] || 'label.unknown';

export const getVaccinationStatusTranslationKey = (value: VaccinationStatus) => `vaccination_status.${value}`;

export const getVaccinationVendorTranslationKey = (value: VaccinationVendor) => `vaccination_vendor.${value}`;

export const getUserReputationTranslationKey = (value: UserReputation) => `label.reputation.${value}`;

export const getExtendedRecordsListTranslationKey = (value: EnvironmentMode) =>
  (value === EnvironmentMode.Test ? 'label.displayProdRecords' : 'label.displayTestRecords');

export const getSpecialProgramSpecialtyTranslationKey = (value: SpecialProgramSpecialty) => `label.jobProgram.${value}`;

export const getSpecialProgramTranslationKey = (value: string) => `label.specialProgram.${value}`;

export const getDocTypeTranslationKey = (value: DocumentType) => `label.docType.${value}`;

export const getAgeFilterTranslationKey = (value: AgeFilterType) => `label.${value.toLowerCase()}`;
export const getCheckoutFieldTypeTranslationKey = (value: CheckoutFieldType) => `label.${value.toLowerCase()}`;
export const getLandingPageTranslationKey = (value: LandingPageType) => `label.landingPage.${value.toLowerCase()}`;
export const getAnalyticsDateTranslationKey = (value: AnalyticsDateType) => `label.${value.toLowerCase()}`;
export const getShopStatusTranslationKey = (value: ShopStatusType) => `label.${value.toLowerCase()}`;
export const getPerformingFilterTranslationKey = (value: ShopStatusType) => `label.performingFilter.${value.toLowerCase()}`;
export const getChatStatusTranslationKey = (value: ChatStatus) => `status.${value.toLowerCase()}`;
export const getOrderStatusTranslationKey = (value: OrderStatus) => `label.${value.toLowerCase()}`;
export const getPaymentMethodTranslationKey = (value: PaymentMethod) => `label.${value.toLowerCase()}`;
export const getAnonUsersTranslationKey = (value: AnonUsersType) => `label.${value.toLowerCase()}`;
export const getMedicalIdPromptTranslationKey = (value: MedicalIdPromptType) => `label.${value.toLowerCase()}`;
export const getCustomerBalanceTranslationKey = (value: CustomerBalanceType) => `label.${value.toLowerCase()}`;
export const getDeliveryMethodTranslationKey = (value: DeliveryMethod) => `label.${value.toLowerCase()}`;

export const getUserAuthStatusTranslationKey = (value: UserAuthStatus) => `label.userAuthStatus.${value}`;
export const getProductSortingTranslationKey = (value: ProductSortOptions) => `label.sorting.${value}`;

export const getBannerTypeTranslationKey = (value: BannerType) => `label.bannerType.${value.toLowerCase()}`;

export const getTargetAppTranslationKey = (value: TargetApp) => `label.targetApp.${value.toLowerCase()}`;

export const getAttachmentActionTranslationKey = (value: AttachmentAction) => `label.attachmentAction.${value.toLowerCase()}`;

export const getInviteeRoleTranslationKey = (value: InviteeRoles) => `label.role.${value}`;

export const getConfirmationModalTitleTranslationKey = (value: ConfirmationModalType) =>
  `notification.${value}.confirmationModal.title`;

export const getBountyStateTranslationKey = (value: BountyState) => `status.${value.toLowerCase()}`;

export const getAuditVerbTranslationKey = (value: AuditVerb) => `audit.verb.${value}`;

export const getAuditPhaseTranslationKey = (value: ApplicationJourneyPhase) => `audit.phase.${value}`;

export const getHoursOperationTypeTranslationKey = (value: HoursOfOperationType) => `label.hoursType.${value}`;

export const getDayHourOperationStatusTranslationKey = (value: OperationStatus) => `label.operationStatus.${value.toLowerCase()}`;

export const getDayOfWeekTranslationKey = (value: DayOfWeekType) => `label.day.${value.toLowerCase()}`;

export const getDayOfWeekPrefixTranslationKey = (value: DayOfWeekType) => `label.prefix.day.${value.toLowerCase()}`;

export const getInvitationStatusTranslationKey = (value: InvitationStatus) => `label.status.${value.toLowerCase()}`;

export const getCurrencyStatusTranslationKey = (value: CustomCurrencyStatus) => `status.${value.toLowerCase()}`;

export const getReportTypeTranslationKey = (value: ReportType) => `label.reportType.${value.toLowerCase()}`;

export const getAlertTypeTranslationKey = (value: TemplateType) => `label.alertType.${value.toLowerCase()}`;

export const getTerminalPositionTranslationKey = (value: TerminalPosition) => `label.terminalPosition.${value.toLowerCase()}`;

export const getStartButtonPositionTranslationKey = (value: StartButtonPosition) => `label.position.${value.toLowerCase()}`;

export const getReservationStatusTranslationKey = (value: ReservationStatus) => `label.status.${value.toLowerCase()}`;

export const getReservationAttributeTranslationKey = (value: ReservationAttribute) => `label.attribute.${value.toLowerCase()}`;

export const getViewModeTranslationKey = (value: ViewMode) => `label.viewMode.${value.toLowerCase()}`;

export const getAddressAutocompleteTranslationKey = (value: AddressAutocomplete) => `label.addressAutocomplete.${value.toLowerCase()}`;

export const getChemicalViewModeTranslationKey = (value: ChemicalViewMode) => `label.chemicalViewMode.${value.toLowerCase()}`;

export const getChemicalKindTranslationKey = (value: ChemicalKind) => `label.chemicalKind.${value.toLowerCase()}`;

export const getTribalIdTranslationKey = (value: TribalIdMode) => `label.tribalIdMode.${value.toLowerCase()}`;

export const getChemicalFiltersTranslationKey = (value: ChemicalFilters) => `label.${value.toLowerCase()}`;

export const getPolicyTypeTranslationKey = (value: PolicyType) => `label.policyType.${value.toLowerCase()}`;

export const getReportReasonTranslationKey = (value: ReportReasonTypes) => `label.report.${value.toLowerCase()}`;

export const getSupportChatTranslationKey = (value: SupportChat) => `label.chat.${value.toLowerCase()}`;

export const getMerchantOrderStatusTranslationKey = (value: MerchantOrderStatus) => `label.${value.toLowerCase()}`;

export const getSeoTypeTranslationKey = (value: SEOType) => `label.seo.${value.toLowerCase()}`;

export const getSeoTypeDetailsTranslationKey = (value: SEOType) => `label.seo.${value.toLowerCase()}.details`;

export const getClientStatusTranslationKey = (value: ClientStatus) => `label.status.${value.toLowerCase()}`;

export const getSchemeTranslationKey = (value: Scheme) => `label.scheme.${value.toLowerCase()}`;

export const getDeliveryFeeSourceTranslationKey = (value: DeliveryFeeSource) => `label.deliveryFeeSource.${value.toLowerCase()}`;

export const getFlwrQuantityVariantTranslationKey = (value: FlwrQuantityVariant) => `label.flwr.${value.toLowerCase()}`;

export const getWeightUnitTranslationKey = (value: WeightUnit) => `label.weightUnit.${value.toLowerCase()}`;

export const getPosTypeTranslationKey = (value: PosType) => `label.posType.${value.toLowerCase()}`;
